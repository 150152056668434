import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/auth/Login";
import Lobby from "./pages/lobby/Lobby";
import SlotGame from "./pages/slotGames/Game";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/utils/ProtectedRoute";

const App: React.FC = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (
      /Mobile|Android|iP(ad|hone|od)|Opera Mini|IEMobile|WPDesktop/.test(
        userAgent
      )
    ) {
      localStorage.setItem("deviceType", "mobile");
    } else if (/Tablet|iPad/.test(userAgent)) {
      localStorage.setItem("deviceType", "tablet");
    } else {
      localStorage.setItem("deviceType", "desktop");
    }
  }, []);

  return (
    <Router>
      <div className="App bg-[url(https://cdn.bougeegames.com/bg.png)] bg-no-repeat bg-center bg-cover bg-fixed">
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={sessionStorage.getItem("token") ? "/lobby" : "/login"}
                replace
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/lobby" element={<Lobby />} />
          <Route path="/slot-games/:gameId" element={<SlotGame />} />
          <Route element={<ProtectedRoute />}>
           
            
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
