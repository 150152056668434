import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import homeIcon from "../../assets/icons/homeIcon.svg";
import { RootState } from "../../store/store";
import { tablesFiltered } from "../../store/tablesSlice";
import { SpriteAnimation } from "./SpriteAnimation";

export default function SideNav({
  excludeTableId,
}: {
  excludeTableId?: string;
}) {
  const navigate = useNavigate();
  const favTables = useSelector((state: RootState) => state.favTables);
  const filteredTables = useSelector((state: RootState) =>
    tablesFiltered(state, { tables: "all" }, favTables, excludeTableId)
  );
  const frameDimensions = {
    width: 200,
    height: 160,
  };

  const reditectTo = (path: string) => {
    navigate(path);
    // window.location.reload();
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full items-baseline p-4 justify-center items-center">
        {/* <div
          className="flex flex-col cursor-pointer justify-center items-center w-min p-1"
          onClick={() => reditectTo("/lobby")}
        >
          <img src={homeIcon} alt="Lobby" />
          <div className="text-xs text-white font-bold">Lobby</div>
        </div> */}
       
      </div>
      <div className="flex flex-col p-3 max-h-[90%] overflow-y-auto">
        {filteredTables.map((_table) => (
          <div
            className="flex flex-col w-[95%] hover:scale-[1.07]"
            key={_table.tableId + "_game_block"}
          >
            <SpriteAnimation
              spriteSheetImage={`game_thumbnail_sprites/${_table.slug}.png`}
              frameWidth={frameDimensions.width}
              frameHeight={frameDimensions.height}
              onClick={() => reditectTo(`/slot-games/${_table.slug}`)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
