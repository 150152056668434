import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { Table } from "../../store/tablesModel";
import { SpriteAnimation } from "./SpriteAnimation";

interface GameTabProps {
  table: Table;
  favTables: string[];
  onGameClick: (gameId: string) => void;
}

const GameTab: React.FC<GameTabProps> = ({
  table,
  favTables,
  onGameClick,
}) => {
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const deviceType = localStorage.getItem("deviceType");

  const updateDimensions = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const sidebarWidth = 0;
    const availableWidth = vw - sidebarWidth - 35; // Account for sidebar and padding
    let baseWidth: number;

    if (vw < 640) { // mobile
      baseWidth = availableWidth / 2; // 2 columns
    } else if (vw < 768) { // sm
      baseWidth = availableWidth / 3; // 3 columns
    } else if (vw < 1024) { // md
      baseWidth = availableWidth / 3; // 3 columns
    } else if (vw < 1280) { // lg
      baseWidth = availableWidth / 3; // 3 columns
    } else { // xl
      baseWidth = availableWidth / 4; // 4 columns
    }

    // Maintain aspect ratio (4:3)
    const baseHeight = (baseWidth * 3) / 4;

    setDimensions({
      width: Math.floor(baseWidth),
      height: Math.floor(baseHeight),
    });
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    window.addEventListener("orientationchange", updateDimensions); // Added orientationchange listener

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener("orientationchange", updateDimensions); // Clean up orientationchange listener
    };
  }, [deviceType]);

  const handleFavClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/favorites",
        {
          tableId: table.tableId,
          isFav: !favTables.includes(table.tableId),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.data && response.data.status === "RS_OK") {
        dispatch(favTablesLoaded(response.data.favTables));
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <div className="relative w-full h-full">
      <SpriteAnimation
        spriteSheetImage={`game_thumbnail_sprites/${table.slug}.png`}
        frameWidth={dimensions.width}
        frameHeight={dimensions.height}
        onClick={() => onGameClick(table.slug)}
        thumbnail = {true}
      />
      <button
        onClick={handleFavClick}
        className="absolute z-10"
        style={{
          bottom: `${dimensions.height * 0.13}px`,
          right: `${dimensions.width * (window.innerWidth < 640 ? 0.03 : 0.02)}px`,
          width: `${dimensions.width * 0.12}px`,
          height: `${dimensions.width * 0.12}px`,
        }}
      >
        <img
          src={favTables.includes(table.tableId) ? "https://cdn.bougeegames.com/icons/fav_active_icon.png" : "https://cdn.bougeegames.com/icons/fav_icon.png"}
          alt="favorite"
          className="w-full h-full object-contain"
        />
      </button>
    </div>
  );
};

export default GameTab;
