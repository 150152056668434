export const TableDataMap = {
    "buffalo-safari": {
        favIconPositionWeb: "top-[13.5%] right-[1%]",
        favIconPositionMob: "top-[12.5%] right-[1%]",
    },
    "cleopatra": { 
        favIconPositionWeb: "top-[13.5%] right-[1.5%]",
        favIconPositionMob: "top-[12.5%] right-[1.5%]",
    },
    "china-street": {
        favIconPositionWeb: "top-[12.8%] right-[1.8%]",
        favIconPositionMob: "top-[11.8%] right-[1.8%]",
    },
    "fortune-lion": {
        favIconPositionWeb: "top-[13.5%] right-[1%]",
        favIconPositionMob: "top-[12.5%] right-[1%]",
    },
    "fruit777": {
        favIconPositionWeb: "top-[11.8%] right-[1.8%]",
        favIconPositionMob: "top-[11.8%] right-[1.8%]",
    },
    "golden-wheel": {
        favIconPositionWeb: "top-[13%] right-[1.8%]",
        favIconPositionMob: "top-[12%] right-[1.8%]",
    },
    "life-in-luxury": {
        favIconPositionWeb: "top-[13.5%] right-[1.2%]",
        favIconPositionMob: "top-[12.5%] right-[1.2%]",
    },
    "pirates-of-caribbean": {
        favIconPositionWeb: "top-[13.5%] right-[1.5%]",
        favIconPositionMob: "top-[12.5%] right-[1.5%]",
    },
    "keno": {
        favIconPositionWeb: "top-[13%] right-[1.2%]",
        favIconPositionMob: "top-[12%] right-[1.8%]",
    },
    // "vegas777": {
    //     favIconPositionWeb: "top-[13.5%] right-[1%]",
    //     favIconPositionMob: "top-[12.5%] right-[1.2%]",
    // },
    "xmas-magic": {
        favIconPositionWeb: "top-[13.5%] right-[1.2%]",
        favIconPositionMob: "top-[12.5%] right-[1.2%]",
    }
}