import React, { useEffect, useState } from "react";
import { SpriteAnimation } from "../SpriteAnimation";
// Refer to App.css button section for styles

interface ButtonProps {
  text: string; // The text to be displayed on the button
  type?: "button" | "submit" | "reset"; // Optional type of the button
  classes?: string; // Optional additional CSS classes for styling
  onClick?: () => void; // Optional function to handle click event
}

const Button: React.FC<ButtonProps> = ({
  text,
  type = "button",
  classes = "",
  onClick,
}) => {
  const [frameDimensions, setFrameDimensions] = useState({
    width: 300,
    height: 100,
  });
  const deviceType = localStorage.getItem("deviceType");

  useEffect(() => {
    if (deviceType === "desktop") {
      setFrameDimensions({ width: 250, height: 90 });
    } else {
      setFrameDimensions({ width: 200, height: 67 });
    }
  }, [deviceType]);

  const handleClick = () => {
    if (!onClick) return;
    onClick();
  };

  return (
    <SpriteAnimation
      spriteSheetImage="sprites/button_bg.png"
      frameWidth={frameDimensions.width}
      frameHeight={frameDimensions.height}
      onClick={() => handleClick()}
    >
      <button
        type={type}
        className={`w-full h-full text-center py-1 ${
          deviceType === "desktop" ? "text-[20px] px-3" : "text-[14px] px-2"
        }`}
        onClick={handleClick}
      >
        {text}
      </button>
    </SpriteAnimation>
  );
};

export default Button;
