import { forwardRef, useState } from "react";
import ChangePassword from "../auth/ChangePassword";
import Logout from "../auth/Logout";

interface PopoverMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

const PopoverMenu = forwardRef<HTMLDivElement, PopoverMenuProps>(
  ({ isOpen, toggleMenu }, ref) => {
    const deviceType = localStorage.getItem("deviceType");
    const [isChangePasswordOpen, setIsChangePasswordOpen] =
      useState<boolean>(false);
    const [isLogoutOpen, setIsLogoutOpen] = useState<boolean>(false);

    return (
      <>
        {isOpen && (
          <div
            className="flex w-full h-full fixed top-0 left-0 z-[1000] items-center justify-center"
            onClick={toggleMenu}
          >
            <div
              className={`absolute bg-[#0D0E0F] z-[1000] border-[3px] border-[#e3c4a3] shadow-[0px_4px_8px_rgba(0,0,0,0.3)] ${
                deviceType === "desktop"
                  ? "top-[80px] right-6 rounded-2xl p-3 pb-5 min-w-[200px]"
                  : "top-[75px] right-3 rounded-xl px-2 py-3 "
              }`}
              onMouseLeave={toggleMenu}
            >
              <ul className="list-none p-0 m-0">
                <li
                  className={`gradient-text cursor-pointer ${
                    deviceType === "desktop"
                      ? "font-semibold text-xl pb-4 hover:scale-[1.08]"
                      : "font-medium text-sm"
                  }`}
                  onClick={() => setIsChangePasswordOpen(true)}
                >
                  Change Password
                </li>
                <li
                  className={`gradient-text cursor-pointer ${
                    deviceType === "desktop"
                      ? "font-semibold text-xl hover:scale-[1.08]"
                      : "font-medium text-sm mt-2"
                  }`}
                  onClick={() => setIsLogoutOpen(true)}
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
        )}
        {isChangePasswordOpen && (
          <ChangePassword
            isOpen={isChangePasswordOpen}
            onClose={() => setIsChangePasswordOpen(false)}
          />
        )}
        {isLogoutOpen && (
          <Logout
            isOpen={isLogoutOpen}
            onClose={() => setIsLogoutOpen(false)}
          />
        )}
      </>
    );
  }
);

export default PopoverMenu;
