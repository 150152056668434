import { useEffect, useState } from "react";

interface SpriteAnimationProps {
  spriteSheetImage: string;
  frameWidth: number;
  frameHeight: number;
  fps?: number;
  totalFrames?: number;
  rows?: number;
  cols?: number;
  delay?: number;
  onClick?: () => void;
  classes?: string;
  thumbnail?: boolean;
}

export function SpriteAnimation({
  spriteSheetImage,
  frameWidth,
  frameHeight,
  fps = 16,
  totalFrames = 25,
  rows = 5,
  cols = 5,
  delay = 0,
  onClick,
  classes,
  children,
  thumbnail=false
}: SpriteAnimationProps & { children?: React.ReactNode }) {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const TOTAL_FRAMES = totalFrames;
  const ROWS = rows;
  const COLS = cols;

  useEffect(() => {
    const image = new Image();
    image.src = `https://cdn.bougeegames.com/${spriteSheetImage}`;
    image.onload = () => setIsImageLoaded(true);
  }, [spriteSheetImage]);

  useEffect(() => {
    if (!isImageLoaded) return;

    let intervalId: NodeJS.Timeout;

    if (isPlaying) {
      intervalId = setInterval(() => {
        setCurrentFrame((prev) => {
          if (prev + 1 === TOTAL_FRAMES) {
            setIsPlaying(false);
            return prev;
          }
          return (prev + 1) % TOTAL_FRAMES;
        });
      }, 1000 / fps);
    } else {
      intervalId = setTimeout(() => {
        setCurrentFrame(0); // Reset to the first frame
        setIsPlaying(true); // Restart animation
      }, delay);
    }

    return () => clearInterval(intervalId);
  }, [fps, TOTAL_FRAMES, isPlaying, delay, isImageLoaded]);

  const row = Math.floor(currentFrame / COLS);
  const col = currentFrame % COLS;

  const spriteSheet = require(`../../assets/${spriteSheetImage}`);

  return (
    <div
      className={`relative ${onClick ? "cursor-pointer" : ""} ${classes}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      style={{
        width: frameWidth,
        height: frameHeight,
        position: "relative", // Parent container for overlays
      }}
    >
     {thumbnail&&(
      <img
        src={spriteSheet}
        alt="Sprite Animation Thumbnail"
        style={{
          width: frameWidth-15,
          height: frameHeight-15,
          // objectFit: "cover",
          position: isImageLoaded ? "absolute" : "relative", // Hide when CDN image is loaded
          // top: 0,
          // left: 0,
          // marginTop:'30px',
          transform: "translate(8px, 8px)",
          zIndex: isImageLoaded ? -1 : 1, // Show thumbnail initially
          opacity: isImageLoaded ? 0 : 1, // Fade out the thumbnail
          transition: "opacity 0.3s ease", // Smooth transition
        }}
      />
    )}
      {/* CDN sprite sheet */}
      {isImageLoaded && (
        <div
        style={{
          width: frameWidth,
          height: frameHeight,
          backgroundImage: isImageLoaded
            ? `url(https://cdn.bougeegames.com/${spriteSheetImage})`
            : "none", // Avoid flickering by not displaying a partially loaded image
          backgroundPosition: `-${col * frameWidth}px -${row * frameHeight}px`,
          backgroundSize: `${frameWidth * COLS}px ${frameHeight * ROWS}px`,
          imageRendering: "pixelated",
          position: "relative", // Parent container for overlays
        }}
        >
          {children}
          </div>
      )}
    </div>
  );
}