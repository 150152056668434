import Cookies from "js-cookie";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  enterFullScreen,
  isMobileDevice,
  lockOrientation,
} from "../utils/fullscreenUtils";
import { SpriteAnimation } from "../utils/SpriteAnimation";
import { useWebSocket } from "../utils/WebSocket";
import GamePopups from "../utils/GamePopups";

declare global {
  interface Window {
    redirectToHome: () => void;
    setMusicVolume: (val: number) => void;
    setSoundVolume: (val: number) => void;
    SendMessageToJS: (message: string) => void;
    openLowBalancePopup: () => void;
    openInActivePopup: () => void;
  }
}
interface UnityWebGLPlayerProps {
  table: {
    slug: string;
    tableName: string;
    orientation: "landscape-primary" | "portrait-primary";
  };
}

const UnityWebGLPlayer: React.FC<UnityWebGLPlayerProps> = ({ table }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const unityInstanceRef = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [popupName, setPopupName] = useState<string>(""); // Store game status
  const deviceType = localStorage.getItem("deviceType");
  const username = sessionStorage.getItem("username");
  const navigate = useNavigate();

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as const,
      width: table.orientation === "portrait-primary" ? "420px" : "100%",
      height:
        table.orientation === "portrait-primary"
          ? "100%"
          : `min(640px, ${deviceType === "desktop" ? "80%" : "100%"})`,
      position: "relative" as const,
      backgroundColor: "#212123",
      margin: "auto",
    },
    canvas: {
      flexGrow: 1,
      display: "block",
      width: "100%",
      height: "100%",
    },
    loader: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#fff",
      fontSize: "1.5em",
      fontFamily: "Arial, sans-serif",
    },
    // video: {
    //   width: "100%",
    //   height: "100%",
    //   position: "absolute" as const,
    //   top: 0,
    //   left: 0,
    //   objectFit: "cover" as const, // Ensures the video covers the container
    //   zIndex: 10,
    // },
  };
  useEffect(() => {
    const redirectToHome = async () => {
      await quitUnityInstance();
      navigate("/lobby");
    };

    const openInActivePopup = async () => {
      setPopupName("Inactive");
      setIsPopupOpen(true);
    };

    const openLowBalancePopup = async () => {
      setPopupName("LowBalance");
      setIsPopupOpen(true);
    };

    const setMusicVolume = (val: number) => {
      Cookies.set(`${username}_music`, val.toFixed(2), { expires: 365 });
    };

    const setSoundVolume = (val: number) => {
      Cookies.set(`${username}_sound`, val.toFixed(2), { expires: 365 });
    };

    const SendMessageToJS = (message: string) => {
      if (message === "HelloFromUnity") {
        return;
      }
      console.log("__check Sending message to server in player: ", message);
      sendMessage(message);
    };

    window.redirectToHome = redirectToHome;
    window.setMusicVolume = setMusicVolume;
    window.setSoundVolume = setSoundVolume;
    window.SendMessageToJS = SendMessageToJS;
    window.openInActivePopup = openInActivePopup;
    window.openLowBalancePopup = openLowBalancePopup;
  }, [navigate, username]);

  const initializeUnityInstance = useCallback(() => {
    const buildUrl = `/UnityBuilds/${table.slug}`;
    const loaderUrl = `${buildUrl}/Build.loader.js`;
    const config = {
      dataUrl: `${buildUrl}/Build.data.unityweb`,
      frameworkUrl: `${buildUrl}/Build.framework.js.unityweb`,
      codeUrl: `${buildUrl}/Build.wasm.unityweb`,
      companyName: "slot-games",
      productName: table.tableName,
      productVersion: "1.0",
    };

    const script = document.createElement("script");
    script.src = loaderUrl;

    script.onload = () => {
      if (typeof createUnityInstance !== "undefined" && canvasRef.current) {
        createUnityInstance(canvasRef.current, config, (progress) => {
          setLoading(progress !== 1);
        })
          .then((unityInstance) => {
            unityInstanceRef.current = unityInstance;
            if (sessionStorage.getItem("token")) {
              unityInstance.SendMessage(
                "WebSocket",
                "OnServerUrlReceived",
                "https://backend.inferixai.link"
              );
              unityInstance.SendMessage(
                "WebSocket",
                "OnJSSessionVarReceived",
                sessionStorage.getItem("token")
              );
              setTimeout(() => {
                unityInstance.SendMessage(
                  "SoundManager",
                  "MusicSettingsFromWeb",
                  parseFloat(Cookies.get(`${username}_music`) || "0.50")
                );
                unityInstance.SendMessage(
                  "SoundManager",
                  "SoundSettingsFromWeb",
                  parseFloat(Cookies.get(`${username}_sound`) || "0.50")
                );
              }, 5000);
            }
            console.log("Unity instance initialized successfully.");
          })
          .catch((err) =>
            console.error("Unity instance initialization error:", err)
          );
      } else {
        console.error("createUnityInstance is not defined.");
      }
    };
    document.body.appendChild(script);
  }, [table, username]);

  const adjustForDevice = useCallback(() => {
    const container = containerRef.current;
    const isMobile = isMobileDevice();

    if (isMobile && container) {
      console.log("Enabling fullscreen for mobile.");
      enterFullScreen(container);
      lockOrientation(table.orientation);
    } else {
      console.log("Desktop detected. No fullscreen triggered.");
    }
  }, [table]);

  const quitUnityInstance = async () => {
    if (unityInstanceRef.current) {
      try {
        await unityInstanceRef.current.Quit();
        console.log("Successfully quit Unity instance.");
        unityInstanceRef.current = null;
      } catch (error) {
        console.error("Failed to quit Unity instance:", error);
      }
    }
  };

  useEffect(() => {
    initializeUnityInstance();
    adjustForDevice();

    return () => {
      if (unityInstanceRef.current) {
        unityInstanceRef.current
          .Quit()
          .then(() => {
            console.log("successfully quit");
          })
          .catch((e: any) => {
            console.log("failed to quit with exception", e);
          });
      }
    };
  }, [initializeUnityInstance, adjustForDevice]);

  const { sendMessage, addMessageListener, removeMessageListener } =
    useWebSocket({
      onOpen: () => console.log("UnityPlayer WebSocket connection opened"),
      onClose: () => console.log("UnityPlayer WebSocket connection closed"),
      onError: (error) => console.error("UnityPlayer WebSocket error:", error),
    });

  const handleMessage = (data: any) => {
    if (unityInstanceRef.current) {
      unityInstanceRef.current.SendMessage(
        "WebSocketBridge",
        "ReceiveMessageFromJS",
        JSON.stringify(data)
      );
    }
  };

  useEffect(() => {
    addMessageListener(handleMessage);
    return () => removeMessageListener(handleMessage);
  }, [addMessageListener, removeMessageListener]);

  useEffect(() => {
    const checkConnectivity = async () => {
      let stat = "No Internet";
  
      const authToken = sessionStorage.getItem("token");
      try {
        // Make a test API call with a timeout of 5 seconds
        const tablesResponse = await axios.get("https://stage-api.bougeegames.com/api/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          timeout: 3000,
        });
  
        if (tablesResponse.status === 200) {
          stat = "Wi-Fi";
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Axios-specific error handling
          if (error.code === "ECONNABORTED") {
            console.error("API call timed out:", error.message);
            stat = "No Internet"; // Set to "No Internet" on timeout
          } else if (error.response) {
            console.error(
              "API call failed with response:",
              error.response.status,
              error.response.data
            );
            stat = "No Internet";
          } else {
            console.error("API call failed with no response:", error.message);
            stat = "No Internet";
          }
        } else {
          // Non-Axios errors
          console.error("Unexpected error:", error);
          stat = "No Internet";
        }
      }
  
      // Update status state
      setStatus(stat);
  
      // Send status to Unity
      if (unityInstanceRef.current) {
        unityInstanceRef.current.SendMessage(
          "NetWorkManager",
          "ReceiveNetworkStatusFromJS",
          stat
        );
      }
    };
  
    checkConnectivity();
  
    // Periodically check connectivity
    const intervalId = setInterval(checkConnectivity, 5000);
  
    // Add event listeners for online/offline
    const handleOnline = () => checkConnectivity();
    const handleOffline = () => checkConnectivity();
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
  
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  
  useEffect(() => {
    if (status === "No Internet") {
      setPopupName("NoInternet");
      setIsPopupOpen(true);
    } else {
      setPopupName("");
      setIsPopupOpen(false);
    }
  }, [status]);
  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  
  const lastClickTime = useRef(0);

  const handleThrottleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const now = Date.now();
      if (now - lastClickTime.current < 300) {
          // Prevent rapid clicks
          event.preventDefault();
          return;
      }
      lastClickTime.current = now;
  };
  return (
    <div id="unity-container" style={styles.container} ref={containerRef} onClick={handleThrottleClick}>
      <canvas id="unity-canvas" style={styles.canvas} ref={canvasRef}></canvas>
      {loading && (
        <div style={styles.loader}>
          <SpriteAnimation
            spriteSheetImage={"sprites/fiesta_loader.png"}
            frameWidth={150}
            frameHeight={150}
            totalFrames={49}
            rows={7}
            cols={7}
            fps={21}
          />
        </div>
      )}
      {/* {loading && (
        <video
          src="/loading.mp4" // Replace with your actual video source
          autoPlay
          loop
          muted
          style={styles.video}
        />
      )} */}
      {isPopupOpen && (
        <GamePopups
          isOpen={isPopupOpen}
          onClose={handlePopupClose}
          popupName={popupName}
        />
      )}
    </div>
  );
};
export default UnityWebGLPlayer;
