import { useEffect, useRef, useState } from "react";
import { useWebSocket } from "../../utils/WebSocket";
import PopoverMenu from "../PopoverMenu";
import Money from "../../../assets/icons/money.png";
import { SpriteAnimation } from "../SpriteAnimation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { tablesFilterUpdated } from "../../../store/filtersSlice";
import { Filters } from "../../../store/filtersModel";
import rupeeImage from "../../../assets/icons/Rupee_Symbol.png"
import "./TopPanel.css";

export default function TopPanel({ jackpotAmount }: { jackpotAmount: number }) {
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deviceType = localStorage.getItem("deviceType");
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  type Tab = "all" | "favs" | "slots";
  const [activeTab, setActiveTab] = useState<Tab>("all");
  const [balance, setBalance] = useState<number>(0);
  const [frameDimensions, setFrameDimensions] = useState({
    width: 52,
    height: 52,
  });
  const [fontSize, setFontSize] = useState({
    title: "text-sm",
    value: "text-xs",
  });
 const userType= sessionStorage.getItem("userType");
  const {
    sendMessage,
    isConnected,
    addMessageListener,
    removeMessageListener,
  } = useWebSocket({
    onOpen: () => {
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      // Send initial balance request
      sendMessage({ operation: "getbalance" }); // Changed to object format
      // Set up new interval for periodic balance updates
      intervalRef.current = setInterval(() => {
        if (isConnected) {
          // Only send if connected
          sendMessage({ operation: "getbalance" });
        }
      }, 10000);
    },
    onClose: () => {
      // console.log("WebSocket connection closed in TopPanel");
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    },
    onError: (error) => {
      // console.error("WebSocket error in TopPanel:", error);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    },
  });

  const handleMessage = (data: any) => {
    // console.log("TopPanel received message:", data);
    const balanceData = data.data;
    if (balanceData && balanceData.balance !== undefined) {
      setBalance(balanceData.balance);
    }
  };

  useEffect(() => {
    addMessageListener(handleMessage);
    return () => removeMessageListener(handleMessage);
  }, [addMessageListener, removeMessageListener]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the popover
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  useEffect(() => {
    const updateFrameSize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
  
        // Adjust frame size proportionally based on parent width
        let mFactor = 0.0575 * 0.01 * parentWidth;
        if (deviceType !== "desktop" && parentWidth > 500) {
          mFactor = 0.08 * 0.01 * parentWidth;
        }
  
        let newWidth = deviceType === "desktop" ? 85 * mFactor : 52;
        let newHeight = newWidth; // Maintain aspect ratio
  
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );
  
        if (vw < 480) {
          newWidth = 40;
          newHeight = 40;
          setFontSize({
            title: "text-xs",
            value: "text-[10px]",
          });
        } else if (vw >= 480 && vw < 768) {
          newWidth = 50;
          newHeight = 50;
          setFontSize({
            title: "text-sm",
            value: "text-xs",
          });
        } else if (vw >= 768) {
          newWidth = 60;
          newHeight = 60;
          setFontSize({
            title: "text-base",
            value: "text-sm",
          });
        }
  
        setFrameDimensions({
          width: newWidth,
          height: newHeight,
        });
      }
    };
  
    // Call updateFrameSize initially
    updateFrameSize();
  
    // Add event listeners for resize and orientation changes
    window.addEventListener("resize", updateFrameSize);
    window.addEventListener("orientationchange", updateFrameSize);
  
    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("resize", updateFrameSize);
      window.removeEventListener("orientationchange", updateFrameSize);
    };
  }, [parentRef, deviceType]);
  

  const handleFilterChange = (_tablesFilter: Filters["tables"]) => {
    try {
      if (_tablesFilter !== filters.tables) {
        dispatch(tablesFilterUpdated(_tablesFilter));
        navigate(
          `/lobby?filters=${encodeURIComponent(
            JSON.stringify({ tables: _tablesFilter })
          )}`
        );
      }
    } catch (e) {
      console.log("error filtering", e);
    }
  };
  const sizeClass = deviceType === "desktop" ? "w-9 h-9" : "w-7 h-7";
  return (
    <>
      <header
        ref={parentRef}
        className="bg-[#40000C] hidden sm:block p-4 fixed w-[100%] h-[85px] -top-[11px] border-2 border-transparent z-50"
        style={{
          borderImage:
            "linear-gradient(to right, #F1CA57 70%, #AF2621 70%, #C7271F 70%, #F1CA57 70%) 1",
        }}
      >
        <div className="flex justify-between items-center  mx-auto">
          <div className="flex items-center gap-4">
            <div className="w-26 h-16  z-10">
              <SpriteAnimation
                spriteSheetImage={"sprites/fiesta_logo.png"}
                frameWidth={deviceType === "desktop" ? 110 : 80}
                frameHeight={deviceType === "desktop" ? 110 : 80}
                totalFrames={49}
                rows={7}
                cols={7}
                fps={21}
                delay={3000}
              />
            </div>
            {/* <div className="flex items-center gap-4">
          <div className="relative w-12 h-12 rounded-full">
            <div className="absolute inset-0 rounded-full p-[2px] bg-gradient-to-r from-[#F1CA57] via-[#FFC608] to-[#FFC052]">
              <div className="h-full w-full rounded-full bg-[#40000C] flex items-center justify-center">
                <img src={"https://cdn.bougeegames.com/icons/money.png"} alt="Jackpot" className="w-9 h-9" />
              </div>
            </div>
          </div>
          <div>
            <div className="text-md text-[#FFB800]">Grand Jackpot</div>
            <div className="text-white">{jackpotAmount}</div>
          </div>
        </div> */}
          </div>
          <div className="flex items-center gap-4 ">
            <nav className="flex justify-center items-center gap-4">
              <button
                onClick={() => (setActiveTab("all"), handleFilterChange("all"))}
                className={`flex items-center gap-1 px-1  rounded-3xl ${
                  activeTab === "all"
                    ? "bg-[#FFB800] bg-opacity-20 text-[#FFC873] border-2 border-[#FFC873] p-2 pr-2 pl-2 font-semibold"
                    : "text-white"
                }`}
              >
                <img
                  src={
                    activeTab === "all"
                      ? "https://cdn.bougeegames.com/icons/All.png"
                      : "https://cdn.bougeegames.com/icons/All inactive.png"
                  }
                  alt="Jackpot"
                  className={sizeClass}
                />
                <span>All</span>
              </button>
              <button
                onClick={() => (
                  setActiveTab("favs"), handleFilterChange("favs")
                )}
                className={`flex items-center gap-1 px-1  rounded-3xl ${
                  activeTab === "favs"
                    ? "bg-[#FFB800] bg-opacity-20 text-[#FFC873] border-2 border-[#FFC873] p-2 pl-2 pr-2 font-semibold"
                    : "text-white"
                }`}
              >
                <img
                  src={
                    activeTab === "favs"
                      ? "https://cdn.bougeegames.com/icons/favs.png"
                      : "https://cdn.bougeegames.com/icons/favs inactive.png"
                  }
                  alt="Jackpot"
                  className={sizeClass}
                />
                <span>Favs</span>
              </button>
              <button
                onClick={() => (
                  setActiveTab("slots"), handleFilterChange("slots")
                )}
                className={`flex items-center gap-1 px-1  rounded-3xl ${
                  activeTab === "slots"
                    ? "bg-[#FFB800] bg-opacity-20 text-[#FFC873] border-2 border-[#FFC873] p-2 pr-2 pl-2 font-semibold"
                    : "text-white"
                }`}
              >
                <img
                  src={
                    activeTab === "slots"
                      ? "https://cdn.bougeegames.com/icons/slots.png"
                      : "https://cdn.bougeegames.com/icons/slots inactive.png"
                  }
                  alt="Jackpot"
                  className={sizeClass}
                />
                <span>Slots</span>
              </button>
            </nav>
          </div>

          <div className="flex items-center gap-4">
            {!(userType==="BOT")&&(
            <div className="flex items-center gap-2 bg-[#40000C] bg-opacity-20 rounded-2xl p-1 border-2 border-[#FFC873]">
              <SpriteAnimation
                spriteSheetImage={`sprites/settings.png`}
                frameWidth={deviceType === "desktop" ? 40 : 30}
                frameHeight={deviceType === "desktop" ? 40 : 30}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            </div>
             )}
            <div className="flex items-center gap-2 bg-[#40000C] bg-opacity-20 rounded-2xl p-1 border-2 border-[#FFC873]">
            {userType==="BOT"?(
              <>
               <SpriteAnimation
            spriteSheetImage={`icons/Rupee_Symbol.png`}
            frameWidth={deviceType === "desktop" ? 40 : 30}
            frameHeight={deviceType === "desktop" ? 40 : 30}
          />
              </>
          ):(
            <>
            <SpriteAnimation
            spriteSheetImage={`sprites/dollar_sign.png`}
            frameWidth={deviceType === "desktop" ? 40 : 30}
            frameHeight={deviceType === "desktop" ? 40 : 30}
          />
             </>
              )}
              <div>
                <div className={`text-white ${
    deviceType=== "desktop" ? "text-sm" : "text-xs"
  }`}>
                  {sessionStorage.getItem("username") || "Unknown"}
                </div>
                <div className={`font-semibold ${
    localStorage.getItem("deviceType") === "desktop"
      ? "text-md text-[#FFB800]"
      : "text-xs text-[#FFB800]"
  }`}>
                  {balance.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* small screens */}
      <header
        className="sm:hidden bg-[#000000] fixed w-full h-[80px] border-b-2 z-50"
        style={{
          borderImage:
            "linear-gradient(to right, #F1CA57, #AF2621, #C7271F, #F1CA57) 1",
        }}
      >
        <div className="flex justify-between items-center p-2">
          {/* <div className="flex items-center gap-3">
          <div className="relative w-9 h-9 rounded-full">
            <div className="absolute inset-0 rounded-full p-[2px] bg-gradient-to-r from-[#F1CA57] via-[#FFC608] to-[#FFC052]">
              <div className="h-full w-full rounded-full bg-[#40000C] flex items-center justify-center">
                <img src={"https://cdn.bougeegames.com/icons/money.png"} alt="Jackpot" className="w-6 h-6" />
              </div>
            </div>
          </div>
          <div>
            <div className={`${fontSize.title} text-[#FFB800]`}>Grand Jackpot</div>
            <div className={`${fontSize.value} text-white`}>{jackpotAmount}</div>
          </div>
        </div> */}
          <div className="flex items-center">
            <div className="w-16 h-16">
              <SpriteAnimation
                spriteSheetImage={"sprites/fiesta_logo.png"}
                frameWidth={frameDimensions.width * 1.7}
                frameHeight={frameDimensions.height * 1.7}
                totalFrames={49}
                rows={7}
                cols={7}
                fps={21}
                delay={3000}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
          {!(userType==="BOT")&&(
            <div className="flex items-center  bg-[#40000C] bg-opacity-20 rounded-2xl p-1 border-2 border-[#FFC873]">
              <SpriteAnimation
                spriteSheetImage={`sprites/settings.png`}
                frameWidth={frameDimensions.width * 0.7}
                frameHeight={frameDimensions.height * 0.7}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            </div>
          )}
            <div className="flex items-center gap-2 bg-[#40000C] bg-opacity-20 rounded-2xl p-1 border-2 border-[#FFC873]">
            {userType==="BOT"?(
              <>
               <SpriteAnimation
            spriteSheetImage={`icons/Rupee_Symbol.png`}
            frameWidth={deviceType === "desktop" ? 40 : 30}
            frameHeight={deviceType === "desktop" ? 40 : 30}
          />
              </>
          ):(
            <>
            <SpriteAnimation
            spriteSheetImage={`sprites/dollar_sign.png`}
            frameWidth={deviceType === "desktop" ? 40 : 30}
            frameHeight={deviceType === "desktop" ? 40 : 30}
          />
             </>
              )}
              <div className="relative flex flex-col items-start min-w-0 overflow-hidden">
                <div
                  className={`${fontSize.value} text-white truncate`}
                  title={sessionStorage.getItem("username") || "Unknown"} // Built-in title attribute as fallback
                >
                  {(sessionStorage.getItem("username") || "Unknown").length >
                    5 && window.innerWidth < 640 // xs screen
                    ? `${sessionStorage.getItem("username") || "Unknown"}`
                    : sessionStorage.getItem("username") || "Unknown"}
                </div>
                <div
                  className={`${fontSize.title} text-[#FFB800] font-semibold`}
                >
                  {balance.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Bottom Navigation */}
      <nav className="sm:hidden fixed bottom-0 left-0 right-0 bg-[#40000C] border-t-2 rounded-t-3xl border-[#FFC873] z-50">
        <div className="flex justify-around py-2">
          <button
            onClick={() => (setActiveTab("all"), handleFilterChange("all"))}
            className={`flex items-center gap-1 px-1  rounded-3xl ${
              activeTab === "all"
                ? "bg-[#FFB800] bg-opacity-20 text-[#FFC873] border-2 border-[#FFC873] p-2 pr-2 pl-2 font-semibold"
                : "text-white"
            }`}
          >
            <img
              src={
                activeTab === "all"
                  ? "https://cdn.bougeegames.com/icons/All.png"
                  : "https://cdn.bougeegames.com/icons/All inactive.png"
              }
              alt="Jackpot"
              className="w-8 h-8"
            />
            <span>All</span>
          </button>
          <button
            onClick={() => (setActiveTab("favs"), handleFilterChange("favs"))}
            className={`flex items-center gap-1 px-1  rounded-3xl ${
              activeTab === "favs"
                ? "bg-[#FFB800] bg-opacity-20 text-[#FFC873] border-2 border-[#FFC873] p-2 pl-2 pr-2 font-semibold"
                : "text-white"
            }`}
          >
            <img
              src={
                activeTab === "favs"
                  ? "https://cdn.bougeegames.com/icons/favs.png"
                  : "https://cdn.bougeegames.com/icons/favs inactive.png"
              }
              alt="Jackpot"
              className="w-8 h-8"
            />
            <span>Favs</span>
          </button>
          <button
            onClick={() => (setActiveTab("slots"), handleFilterChange("slots"))}
            className={`flex items-center gap-1 px-1  rounded-3xl ${
              activeTab === "slots"
                ? "bg-[#FFB800] bg-opacity-20 text-[#FFC873] border-2 border-[#FFC873] p-2 pr-2 pl-2 font-semibold"
                : "text-white"
            }`}
          >
            <img
              src={
                activeTab === "slots"
                  ? "https://cdn.bougeegames.com/icons/slots.png"
                  : "https://cdn.bougeegames.com/icons/slots inactive.png"
              }
              alt="Jackpot"
              className="w-8 h-8"
            />
            <span>Slots</span>
          </button>
        </div>
      </nav>

      <PopoverMenu
        ref={popoverRef}
        isOpen={isPopoverOpen}
        toggleMenu={() => setIsPopoverOpen(false)}
      />
    </>
  );
}
