import axios from "axios";
import NoSleep from "@uriopass/nosleep.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableDataMap } from "../../components/utils/constants/tablesData";
import GameTab from "../../components/utils/GameTab";
import TopPanel from "../../components/utils/topPanel/TopPanel";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { Filters } from "../../store/filtersModel";
import { filtersUpdated } from "../../store/filtersSlice";
import { RootState } from "../../store/store";
import { Table } from "../../store/tablesModel";
import { tablesFiltered, tablesLoaded } from "../../store/tablesSlice";
import OverlaySpinner from "../../components/utils/spinners/OverlaySpinner/OverlaySpinner";
import GamePopups from "../../components/utils/GamePopups";

export default function Lobby() {
  const navigate = useNavigate();
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const [isPortrait, setIsPortrait] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const [isValidating, setIsValidating] = useState(true); // Initially true to prevent rendering
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [jackpotAmount, setJackpotAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupName, setPopupName] = useState<string>("");
  let noSleepInstance: NoSleep | null = null;
  const favTables = useSelector((state: RootState) => state.favTables);
  const filteredTables = useSelector((state: RootState) =>
    tablesFiltered(state, filters, favTables)
  );

  useEffect(() => {
    const validateTokenAndInitialize = async () => {
      const tokenParam = searchParams.get("token");
      const userName = searchParams.get("userName");
      const enterLobby = searchParams.get("lobby");
      const userType = searchParams.get("userType");

      if (tokenParam && userName && enterLobby === "true") {
        setIsValidating(true);
        try {
          const response = await axios.get(
            "https://backend.inferixai.link/api/v1/validate-token",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenParam}`,
              },
            }
          );

          if (response && response.data.description === "Token valid") {
            setToken(tokenParam);
            sessionStorage.setItem("token", tokenParam);
            sessionStorage.setItem("username", userName);
            if (userType) {
              sessionStorage.setItem("userType", userType);
            }

            // Navigate to the same route to remove the token from the URL
            navigate(
              `/lobby?filters=${encodeURIComponent(JSON.stringify(filters))}`,
              {
                replace: true,
              }
            );
          } else {
            throw new Error("Invalid token");
          }
        } catch (err) {
          console.error("Token validation failed", err);
          setError("Invalid token");
        } finally {
          setIsValidating(false);
        }
      } else {
        setIsValidating(false);
      }
    };

    validateTokenAndInitialize();
  }, [searchParams, navigate, filters]);

  useEffect(() => {
    const filtersParam = searchParams?.get("filters");
    const lobbyParam = searchParams?.get("lobby");
    const checktoken = sessionStorage.getItem("token");
    if (checktoken && !lobbyParam) {
      if (!filtersParam) {
        try {
          navigate(
            `/lobby?filters=${encodeURIComponent(JSON.stringify(filters))}`
          );
        } catch (e) {
          console.log("filter error", e);
        }
      }
    } else {
      setError("Token not found");
    }
  }, [searchParams, filters, navigate]);

  useEffect(() => {
    const filtersParam = searchParams?.get("filters");
    const lobbyParam = searchParams?.get("lobby");
    const checktoken = sessionStorage.getItem("token");
    if (checktoken && !lobbyParam) {
      if (filtersParam) {
        try {
          const _filters: Filters = JSON.parse(filtersParam);
          dispatch(filtersUpdated(_filters));
        } catch (error) {
          console.error("Failed to parse filters from URL", error);
        }
      }
    } else {
      setError("Token not found");
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (!token && !sessionStorage.getItem("token")) return;

    const getTables = async () => {
      setError("");

      try {
        setIsLoading(true);
        const authToken = sessionStorage.getItem("token") || token;
        const [tablesResponse, jackpotResponse] = await Promise.all([
          axios.get("https://backend.inferixai.link/api/gettables", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }),
          axios.get("https://backend.inferixai.link/api/get-jackpot", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }),
        ]);

        if (tablesResponse.data && tablesResponse.data.status === "RS_OK") {
          const fetchedTables: Table[] = tablesResponse?.data.tables.map(
            (table: any) => ({
              tableId: table.tableId,
              tableName: table.tableName,
              category: table.category,
              slug: table.slug,
              orientation: table.orientation as
                | "landscape-primary"
                | "portrait-primary",
            })
          );

          const filteredTables = fetchedTables?.filter(
            (table) => table.slug in TableDataMap
          );

          dispatch(tablesLoaded(filteredTables));
          dispatch(favTablesLoaded(tablesResponse.data.favTables));
        } else {
          setIsLoading(false);
          throw new Error(tablesResponse.data.message);
        }
        if (jackpotResponse.data && jackpotResponse.data.code === 200) {
          setJackpotAmount(jackpotResponse.data.jackpot);
        } else {
          throw new Error(jackpotResponse.data.message);
        }
      } catch (err: any) {
        setIsLoading(false);
        console.error(err);
        setError(err.response?.data?.error || "Failed to load tables");
      } finally {
        setIsLoading(false);
      }
    };

    getTables();
  }, [token, dispatch]);

  const enableNoSleep = () => {
    if (!noSleepInstance) {
      noSleepInstance = new NoSleep();
    }
    try {
      noSleepInstance.enable();
      console.log("NoSleep enabled");
    } catch (err) {
      console.error("Failed to enable NoSleep:", err);
    }
  };

  const disableNoSleep = () => {
    if (noSleepInstance) {
      try {
        noSleepInstance.disable();
        console.log("NoSleep disabled");
      } catch (err) {
        console.error("Failed to disable NoSleep:", err);
      }
    }
  };

  useEffect(() => {
    disableNoSleep();
    return () => {
      disableNoSleep();
    };
  }, []);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  useEffect(() => {
    const checkConnectivity = async () => {
      let stat = "No Internet";
      const authToken = sessionStorage.getItem("token");
      try {
        // Make a test API call with a timeout of 5 seconds
        const tablesResponse = await axios.get(
          "https://stage-api.bougeegames.com/api/",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            timeout: 3000,
          }
        );

        if (tablesResponse.status === 200) {
          stat = "Wi-Fi";
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Axios-specific error handling
          if (error.code === "ECONNABORTED") {
            console.error("API call timed out:", error.message);
            stat = "No Internet"; // Set to "No Internet" on timeout
          } else if (error.response) {
            console.error(
              "API call failed with response:",
              error.response.status,
              error.response.data
            );
            stat = "No Internet";
          } else {
            console.error("API call failed with no response:", error.message);
            stat = "No Internet";
          }
        } else {
          console.error("Unexpected error:", error);
          stat = "No Internet";
        }
      }
      setStatus(stat);
    };
    checkConnectivity();
    const intervalId = setInterval(checkConnectivity, 5000);
    const handleOnline = () => checkConnectivity();
    const handleOffline = () => checkConnectivity();
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (status === "No Internet") {
      setPopupName("NoInternet");
      setIsPopupOpen(true);
    } else {
      setPopupName("");
      setIsPopupOpen(false);
    }
  }, [status]);

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  if (isValidating) {
    <div className="w-full p-2  text-red-600 text-3xl font-medium text-center">
      Validating token...
    </div>;
  }

  if (error) {
    return (
      <div className="w-full p-2  text-red-600 text-3xl font-medium text-center">
        {error}
      </div>
    );
  }
  return (
    <div className="flex flex-col h-full">
      <TopPanel jackpotAmount={jackpotAmount} />
      {/* Add margin-top to push the content below the header */}
      <div className="flex flex-grow min-h-0 mt-[58px]">
        <div className="flex-grow overflow-y-auto pl-4 pr-4">
          {isLoading ? (
            <OverlaySpinner />
          ) : (
            <div className="w-full max-w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-4 pr-4 pb-[80px] sm:pb-0 mt-10">
              {filteredTables?.map((_g: Table) => (
                <div
                  key={_g.tableId}
                  className="flex justify-center items-center -mt-[30px]"
                >
                  <GameTab
                    key={`${_g.tableId}_tab`}
                    table={_g}
                    favTables={favTables}
                    onGameClick={(gameId: string) => {
                      enableNoSleep();
                      navigate(`/slot-games/${gameId}`);
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {isPopupOpen && (
        <GamePopups
          isOpen={isPopupOpen}
          onClose={handlePopupClose}
          popupName={popupName}
        />
      )}
    </div>
  );
}
